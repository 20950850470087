import { getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { kebabCase } from 'lodash'

import Breadcrumbs from '~/components/breadcrumbs/Breadcrumbs'
import MainLayout from '~/components/layouts/MainLayout'
import PostBanner from '~/components/shared/PostBanner'
import PostCase from '~/components/shared/PostCase'
import { CategoriesQueryResult } from '~/templates/blog/Categories'
import { PagePropsWithContext, WithRequired } from '~/types/helper'
import { fileToImageLikeData } from '~/utils'
import BlogPagination from '~/views/Blog/components/BlogPagination'
import BlogPostList from '~/views/Blog/components/BlogPostList'
import NavigationCategory from '~/views/Blog/components/NavigationCategory'

import * as containerStyles from '../Blog.module.scss'

const Categories = ({
  data,
  pageContext,
}: PagePropsWithContext<CategoriesQueryResult>) => {
  const strapiCategory = data.strapiCategory as WithRequired<
    Queries.STRAPI_CATEGORY,
    'name' | 'og_image'
  > & {
    name: string
    title: string
    description: string
    categorybanner?: {
      link: string
      title: string
      read_time?: string
      post_date?: string
      description: string
      main_img: IGatsbyImageData
    }
    categoriescases?: {
      link: string
      title: string
      active: boolean
      description: string
      main_img: IGatsbyImageData
    }
  }
  const articles = data.allStrapiArticle.edges.map((article) => article.node)

  const categoryBanner = strapiCategory.categorybanner
  const categoryBannerActive = categoryBanner && categoryBanner.active

  return (
    <MainLayout isStaticChat scrollTop>
      <div className={containerStyles.blog}>
        <div className="container container-md">
          <div className="row">
            <div className="col-12">
              <div className={containerStyles.blog__breadcrumbsWrapper}>
                <Breadcrumbs
                  secondLevel="Blog"
                  secondLevelPath="/blog/"
                  current={strapiCategory.title || ''}
                  currentPath={`/blog/categories/${kebabCase(
                    strapiCategory.name || '',
                  )}/`}
                />
              </div>
              <h1 className={containerStyles.blog__title}>
                {strapiCategory.title}
              </h1>
            </div>

            <NavigationCategory data={data} />
          </div>
        </div>

        {categoryBanner && categoryBannerActive && (
          <div className="container container-md">
            <PostBanner
              isCategoryBanner
              image={getImage(
                fileToImageLikeData(categoryBanner.main_img.localFile),
              )}
              title={categoryBanner.title}
              link={`/${categoryBanner.link}/`}
              readTime={categoryBanner.read_time}
              postDate={categoryBanner.post_date}
              description={categoryBanner.description}
            />
          </div>
        )}

        <PostCase
          categoryName={strapiCategory.name}
          dataCase={strapiCategory.categoriescases}
        />

        <div className="container container-md">
          <div className="row">
            <BlogPostList data={articles} withHeading />

            <BlogPagination pageContext={pageContext} />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Categories
