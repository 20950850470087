import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { ReactComponent as ArrowIcon } from '~/assets/images/icons/arrow-right.svg'
import { cx } from '~/utils'

import * as containerStyles from './PostBanner.module.scss'

interface PostBannerProps {
  image?: IGatsbyImageData
  title: string
  link: string
  postDate?: string
  readTime?: string
  description: string
  isCategoryBanner?: boolean
  loading?: 'lazy' | 'eager' | undefined
}

const PostBanner = ({
  image,
  title,
  link,
  postDate,
  readTime,
  description,
  isCategoryBanner,
  loading = 'lazy',
}: PostBannerProps) => (
  <div
    className={cx(
      'row',
      isCategoryBanner && containerStyles.postBanner__categoryWrapper,
    )}
  >
    <div className="col-12 col-lg-6">
      <GatsbyImage
        image={image as IGatsbyImageData}
        alt={`${title} | Codica`}
        title={title}
        className={containerStyles.postBanner__image}
        loading={loading}
      />
    </div>
    <div className="col-12 col-lg-6">
      <div className={containerStyles.postBanner__infoWrapper}>
        {readTime && postDate && (
          <div className={containerStyles.postBanner__mobileInfo}>
            <div className={containerStyles.postBanner__postTime}>
              {`${readTime} min`}
            </div>
            <div className={containerStyles.postBanner__postDate}>
              {postDate}
            </div>
          </div>
        )}
        <Link to={link} className={containerStyles.postBanner__title}>
          {title}
        </Link>
        <div className={containerStyles.postBanner__description}>
          {description}
        </div>
        <Link to={link} className={containerStyles.postBanner__readArticle}>
          Read article
          <ArrowIcon />
        </Link>
      </div>
    </div>
  </div>
)

export default PostBanner
