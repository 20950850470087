import { Link } from 'gatsby'

import { ReactComponent as ArrowIcon } from '~/assets/images/icons/arrow-right.svg'
import { cx } from '~/utils'

import * as containerStyles from './Pagination.module.scss'

interface TransformParams {
  url: string
  page: number
}

type TransformUrl = (params: TransformParams) => string

interface PaginationProps {
  countPages: number
  currentPage: number
  url: string
  transformUrl?: TransformUrl
}

const defaultTransform: TransformUrl = ({ url, page }): string =>
  page === 1 ? url : `${url}page/${page}/`

const Pagination = ({
  countPages,
  currentPage,
  url,
  transformUrl = defaultTransform,
}: PaginationProps) => {
  let sliceStart
  let sliceEnd

  const prevPage = currentPage - 1

  if (countPages < 5) {
    sliceStart = 1
    sliceEnd = countPages - 1
  } else {
    sliceStart = currentPage < 4 ? 1 : currentPage - 2
    sliceEnd = currentPage + 2

    if (currentPage >= 2) {
      sliceEnd = currentPage + 1
    }

    if (currentPage > countPages - 2) {
      sliceEnd = countPages - 1
    }

    if (currentPage === countPages) {
      sliceStart = currentPage - 3
    }
  }

  return (
    <div className={containerStyles.blogPagination}>
      {prevPage === 0 ? (
        <div
          className={cx(
            containerStyles.blogPagination__prev,
            containerStyles.blogPagination__disabled,
          )}
        >
          <ArrowIcon />
        </div>
      ) : (
        <Link
          aria-label="Pagination prev page"
          to={transformUrl({ url, page: prevPage })}
          className={cx(containerStyles.blogPagination__prev, {
            [containerStyles.blogPagination__disabled]: prevPage === 0,
          })}
        >
          <ArrowIcon />
        </Link>
      )}

      <Link
        aria-label="Pagination first page"
        to={transformUrl({ url, page: 1 })}
        className={cx(containerStyles.blogPagination__item, {
          [containerStyles.blogPagination__active]: currentPage === 1,
        })}
      >
        1
      </Link>

      {currentPage > 3 && countPages > 4 && (
        <span className={containerStyles.blogPagination__item}>...</span>
      )}

      {Array.from({ length: countPages }, (_, i) => {
        const pageNum = i + 1
        const link = transformUrl({ url, page: pageNum })

        return (
          <Link
            aria-label="Pagination page"
            key={`pagination-number${i + 1}`}
            to={link}
            activeClassName={containerStyles.blogPagination__active}
            className={cx(containerStyles.blogPagination__item, {
              [containerStyles.blogPagination__active]: currentPage === pageNum,
            })}
          >
            {pageNum}
          </Link>
        )
      }).slice(sliceStart, sliceEnd)}

      {currentPage <= countPages - 2 && countPages > 4 && (
        <span className={containerStyles.blogPagination__dots}>...</span>
      )}

      <Link
        aria-label="Pagination last page"
        to={transformUrl({ url, page: countPages })}
        className={cx(containerStyles.blogPagination__item, {
          [containerStyles.blogPagination__active]: currentPage === countPages,
        })}
      >
        {countPages}
      </Link>

      {currentPage === countPages ? (
        <div
          className={cx(
            containerStyles.blogPagination__next,
            containerStyles.blogPagination__disabled,
          )}
        >
          <ArrowIcon />
        </div>
      ) : (
        <Link
          aria-label="Pagination next page"
          to={transformUrl({ url, page: currentPage + 1 })}
          className={cx(containerStyles.blogPagination__next, {
            [containerStyles.blogPagination__disabled]:
              currentPage === countPages,
          })}
        >
          <ArrowIcon />
        </Link>
      )}
    </div>
  )
}

export default Pagination
