import EmptyFallback from '~/components/render-kit/EmptyFallback'
import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'
import { Pagination } from '~/components/shared/Pagination'

import * as containerStyles from '../../Blog.module.scss'

const BlogPagination = ({ pageContext }) => {
  const { numberOfPages, humanPageNumber, pageUrl } = pageContext

  return (
    <>
      {numberOfPages > 1 && (
        <div className="col-12">
          <div className={containerStyles.blog__paginationWrapper}>
            <RenderWhenVisible fallback={<EmptyFallback minHeight={38} />}>
              <Pagination
                countPages={numberOfPages}
                currentPage={humanPageNumber}
                url={pageUrl}
              />
            </RenderWhenVisible>
          </div>
        </div>
      )}
    </>
  )
}

export default BlogPagination
