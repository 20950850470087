import { Link } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { cx, fileToImageLikeData } from '~/utils'

import * as containerStyles from './PostCase.module.scss'

const PostCase = ({ dataCase, categoryName }) => {
  const activeCases = dataCase.filter((caseItem) => caseItem.active)

  return (
    <div className="container container-md">
      {activeCases.length > 0 && (
        <div className="row">
          <div className="col-12">
            <h2 className={containerStyles.postCase__categoryName}>
              {categoryName}
              <span>cases</span>
            </h2>
          </div>
        </div>
      )}
      <div className="row">
        {activeCases.map(({ main_img, title, link, description }) => (
          <Link
            key={title}
            to={`/case-studies/${link}/`}
            className={cx('col-12 col-lg-6', containerStyles.postCase__wrapper)}
          >
            <GatsbyImage
              image={
                getImage(
                  fileToImageLikeData(main_img.localFile),
                ) as IGatsbyImageData
              }
              alt={`${title} | Codica`}
              title={title}
              className={containerStyles.postCase__image}
              loading="lazy"
            />
            <div className={containerStyles.postCase__title}>{title}</div>
            <div className={containerStyles.postCase__description}>
              {description}
            </div>
            <div className={containerStyles.postCase__caseBox}>Case Study</div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default PostCase
