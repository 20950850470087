import { Link } from 'gatsby'
import kebabCase from 'lodash/kebabCase'

import { AllStrapiCategory } from '~/types/graphql'
import { cx } from '~/utils'

import { useLocation } from '@reach/router'

import * as containerStyles from './NavigationCategory.module.scss'

export interface NavigationCategoryyProps {
  data: AllStrapiCategory
}

const NavigationCategory = ({ data }: NavigationCategoryyProps) => {
  const { pathname } = useLocation()

  return (
    <div className="col-12">
      <nav className={containerStyles.navigationCategory}>
        <Link
          to="/blog/"
          activeClassName={containerStyles.navigationCategory__linkActive}
          className={containerStyles.navigationCategory__link}
        >
          All
        </Link>

        {data.allStrapiCategory.edges.map((item) => {
          const linkTo = `/blog/categories/${kebabCase(item.node.name || '')}/`
          const isActive = pathname.includes(linkTo)

          return (
            <Link
              key={item.node.name}
              to={linkTo}
              className={cx(containerStyles.navigationCategory__link, {
                [containerStyles.navigationCategory__linkActive]: isActive,
              })}
            >
              {item.node.nav_name}
            </Link>
          )
        })}
      </nav>
    </div>
  )
}

export default NavigationCategory
